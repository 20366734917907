import gql from 'graphql-tag';
export default gql`
query listSignups($limit: Int $nextToken: String){
    listSignups(limit: $limit nextToken: $nextToken){
      items{
        id
        siteId
        mob
        name
        mobile
        email
        profession
        state
        location
        source
        createdAt
        assignUser
      }
      nextToken
    }
  }
  # {
  #   "limit": 10,
  #   "nextToken": ""
  # }`