import React, { Component } from 'react'
import App from '../App'
import { Link } from 'react-router-dom';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Button, Col, DatePicker, Form, Input,Radio, Card,Row, Select, message, Modal, Icon, Spin, Breadcrumb } from 'antd'

import updateSalesUser from '../GraphQl/Mutation/updateSalesUser'
const FormItem = Form.Item
const Option = Select.Option;

const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]


class editSalesPeople extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    enterLoading = () => {
        this.setState({
            loading: true
        })
    }

    messageClose = () => {
        this.props.history.push('/SalesPeople')
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
    console.log(values)
    // return
          if (!err) {
            this.enterLoading()
            try {
              this.props.client.mutate({
                mutation: updateSalesUser,
                variables: {
                    userId:this.props.location.state.data.userId,
                    mobile:values.mob,
                    fName: values.fname,
                    lName: values.lname,
                    gender: values.gender,
                    email: values.email,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                }
              }).then(({ data }) => {
                console.log(data)
                if (data.updateSalesUser != null) {
                  message.success(<span style={{ fontSize: '16px' }}>Sales People updated Successfully</span>,this.messageClose);
                  this.setState({
                    loading: false
                  })
                }
              })
                .catch(err => {
                  console.log(err)
                  this.setState({
                    loading: false

                  })
                });
            }
            catch (err) {
              console.log(err)
              this.setState({
                loading: false

              })
            }
          }
          else {
            console.log(err)
    
          }
        })
      }
    
    render() {
        let data=this.props.location.state && this.props.location.state.data?this.props.location.state.data:{}
        console.log("props",data)

        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <App header={"Create Sales People"}>
                <div>
                    <Form onSubmit={this.handleSubmit}>
                    {/* <Card style={{alignContent:'center'}}> */}
                        <Row gutter={16}>
                         
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="First Name">
                                    {getFieldDecorator(`fname`, {
                                    initialValue:data && data.fName?data.fName:'',
                                        rules: [{
                                            required: true,
                                            message: "Please enter first  name",
                                        },
                                        ]
                                    })(
                                        <Input placeholder=" name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="Last Name">
                                    {getFieldDecorator(`lname`, {
                                    initialValue:data && data.lName?data.lName:'',

                                        rules: [{
                                            required: true,
                                            message: "Please enter last  name",
                                        },
                                        ]
                                    })(
                                        <Input placeholder=" name" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                            
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                    <FormItem
                                        label="Mobile Number"
                                    >
                                        {getFieldDecorator(`mob`, {
                                    initialValue:data && data.mobile?data.mobile:'',

                                            rules: [{
                                                required: true,
                                                message: "Please enter Mobile Number",
                                            },
                                            {
                                                validator: (rule, value, cb) => {
                                                    if (value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)) {
                                                        cb('Please enter 10 digit number only')
                                                    }
                                                    cb()
                                                }
                                            }
                                            ]
                                        })(
                                            <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                        )}
                                    </FormItem>
                                </Col>
                         
                         
                         
                        </Row>
                  
                        <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="Gender">
                                    {getFieldDecorator(`gender`, {
                                    initialValue:data && data.gender?data.gender:'',

                                        rules: [{
                                            required: true,
                                            message: "Please enter gender",
                                        },
                                        ]
                                    })(
                                        <Radio.Group placeholder="Gender" style={{ width: '100%' }} >
                                                 <Radio value={'male'}>Male</Radio>
                                                <Radio value={'female'}>Female</Radio>
                                                </Radio.Group>
                                    )}
                                </FormItem>
                            </Col>


                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem
                                    label="Email"
                                >
                                    {getFieldDecorator(`email`, {
                                    initialValue:data && data.email?data.email:'',

                                        rules: [{
                                            type: 'email',
                                            required: true,
                                            message: "Please enter E-mail!",
                                        },

                                        ]
                                    })(
                                        <Input placeholder="Email" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                         

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="City">
                                    {getFieldDecorator(`city`, {
                                    initialValue:data && data.city?data.city:'',

                                        rules: [{
                                            required: true,
                                            message: "Please enter City",
                                        },
                                        ]
                                    })(
                                        <Input placeholder="City" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>

                         
                         
                           
                        </Row>
                        <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="State">
                                    {getFieldDecorator(`state`, {
                                    initialValue:data && data.state?data.state:'',

                                        rules: [{
                                            required: true,
                                            message: "Please enter state",
                                        },
                                        ]
                                    })(
                                        <Select
                                        optionFilterProp="children"
                                        placeholder="Select State"
                                        showSearch
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                      >
                                        {state.map((c)=>{
                                            return(
                                              <Option key={c.value} value={c.value} >{c.label}</Option>
                                            )
                                        }) }
                                      </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label="Pine Code">
                                    {getFieldDecorator(`zip`, {
                                    initialValue:data && data.zip?data.zip:'',

                                        rules: [{
                                            required: false,
                                            message: "Please enter state",
                                        },
                                        ]
                                    })(
                                        <Input placeholder="Pine Code" style={{ width: '100%' }} />
                                    )}
                                </FormItem>
                            </Col>
                          

                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Button loading={this.state.loading} type="primary" htmlType="submit" style={{ background: "#389e0d", color: "#fff", marginBottom: "5%", marginRight: "20px" }} >
                                        Submit
                                    </Button>
                                    <Link to={{ pathname: '/SalesPeople' }}>
                                        <Button type="default" style={{ background: "#f44336", color: "#fff", marginBottom: "5%" }}   >Cancel</Button>
                                    </Link>
                                </Col>
                            </Row>
        
                        </Form>
                       
                   
                    </div>
                </App>

                )
            }
        }
        const WrappededitSalesPeople = Form.create()(editSalesPeople);
export default withApollo(WrappededitSalesPeople)