import React from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom";
import Reg from './component/Registrations'
import Enquiries from './component/Enquiries'
import REgForm from './component/editDoctor'
import SalesPeople from './component/SalesPeopleList'
import EditSalesPeople from './component/editSalesPeople'
import AddSalesPeople from './component/createSalesPeople'
import SignUpList from './component/SignupList'
const Routers =(props)=>(
    
    <Router >
        <React.Fragment>
            <Route exact path="/" component={Enquiries}/>
            <Route exact path="/enquiries" component={Enquiries}/>
            <Route exact path="/registrations" component={Reg}/>
            <Route exact path="/editdoctor" component={REgForm}/>
            <Route exact path="/SalesPeople" component={SalesPeople}/>
            <Route exact path="/editSalesPeople" component={EditSalesPeople}/>
            <Route exact path="/createSalesPeople" component={AddSalesPeople}/>
            <Route exact path="/SignUpList" component={SignUpList}/>




            </React.Fragment>
    </Router>
);
export default Routers