import gql from 'graphql-tag';
export default gql`

mutation updateDoctor(
    $id: ID!
    $mob: Float!
    $email: String!
    $fName: String!
    $lName: String!
    $fullName: String!
    $gender: Gender!
    $speciality: String
$subSpeciality: String
$clinicName: String
$addressOne: String
$addressTwo: String
$country: String
$state: String
$city: String
$zip: Float
$registrationId: String
$registrationCouncil: String
$registrationYear: Int
$highestQualification: String
$university: String
$degreeYear: Int
$experienceYears: Int
$identityProofDoc: S3ObjectInput
$registrationProofDoc: S3ObjectInput
$clinicAddressProofDoc: S3ObjectInput

  ){
    updateDoctor(input:{
      id: $id
      mob: $mob
      email: $email
      fName: $fName
      lName: $lName
      fullName: $fullName
      gender: $gender
      speciality:$speciality
      subSpeciality:$subSpeciality
      clinicName:$clinicName
      addressOne:$addressOne
      addressTwo:$addressTwo
      country:$country
      state:$state
      city:$city
      zip:$zip
      registrationId:$registrationId
      registrationCouncil:$registrationCouncil
      registrationYear:$registrationYear
      highestQualification:$highestQualification
      university:$university
      degreeYear:$degreeYear
      experienceYears:$experienceYears
      identityProofDoc:$identityProofDoc
      registrationProofDoc:$registrationProofDoc
      clinicAddressProofDoc:$clinicAddressProofDoc
    }){
      id
      mob
      email
      fName
      lName
      fullName
      gender
      speciality
      subSpeciality
      clinicName
      addressOne
      addressTwo
      country
      state
      city
      zip
      registrationId
      registrationCouncil
      registrationYear
      highestQualification
      university
      degreeYear
      experienceYears
      identityProofDoc{
        bucket
        key
        region
      }
      registrationProofDoc{
        bucket
        key
        region
      }
      clinicAddressProofDoc{
        bucket
        key
        region
      }
    }
  }
  `
  