import gql from 'graphql-tag';
export default gql`
mutation updateStatus(
    $id: ID!
    $status: Status!
    ){
        updateStatus(
          id: $id
          status: $status
        ){
            id
      state
      status
      fullName
        }
    }`
