import React, { Component } from 'react'
import App from '../App'
import { Link } from 'react-router-dom';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { Button, Col, DatePicker, Form, Input, Card,Row, Select,Upload, message,Radio, Modal, Icon, Spin, Breadcrumb } from 'antd'
import updateDoctor from '../GraphQl/Mutation/updateDoctor'
const FormItem = Form.Item
const Option = Select.Option;
const bucket =  'teledocsproofdocsdev223722-prod';
const region = 'ap-south-1';


const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]

class addBuyer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            clinicAddressProofDoc: this.props.location.state.data.clinicAddressProofDoc != null ? {
                key: this.props.location.state.data.clinicAddressProofDoc.key,
                uid: this.props.location.state.data.clinicAddressProofDoc.key,
                bucket: this.props.location.state.data.clinicAddressProofDoc.bucket,
                region: this.props.location.state.data.clinicAddressProofDoc.region,
                status: 'done',
                url: `https://${this.props.location.state.data.clinicAddressProofDoc.bucket}.s3.${this.props.location.state.data.clinicAddressProofDoc.region}.amazonaws.com/${this.props.location.state.data.clinicAddressProofDoc.key}`
            }: {},
            removedclinicAddressProofDoc: false,
            identityProofDoc: this.props.location.state.data.identityProofDoc != null ? {
                key: this.props.location.state.data.identityProofDoc.key,
                uid: this.props.location.state.data.identityProofDoc.key,
                bucket: this.props.location.state.data.identityProofDoc.bucket,
                region: this.props.location.state.data.identityProofDoc.region,
                status: 'done',
                url: `https://${this.props.location.state.data.identityProofDoc.bucket}.s3.${this.props.location.state.data.identityProofDoc.region}.amazonaws.com/${this.props.location.state.data.identityProofDoc.key}`
            }: {},
            removedidentityProofDoc: false,
            registrationProofDoc: this.props.location.state.data.registrationProofDoc != null ? {
                key: this.props.location.state.data.registrationProofDoc.key,
                uid: this.props.location.state.data.registrationProofDoc.key,
                bucket: this.props.location.state.data.registrationProofDoc.bucket,
                region: this.props.location.state.data.registrationProofDoc.region,
                status: 'done',
                url: `https://${this.props.location.state.data.registrationProofDoc.bucket}.s3.${this.props.location.state.data.registrationProofDoc.region}.amazonaws.com/${this.props.location.state.data.registrationProofDoc.key}`
            }: {},
            removedregistrationProofDoc: false,
        }
    }

    handleMainImageAddProof = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.clinicAddressProofDoc).length === 0 && prevState.removedclinicAddressProofDoc === false){
                return{
                    clinicAddressProofDoc: file,
                    removedclinicAddressProofDoc: false
                }
            }
            else{
                return{
                    // clinicAddressProofDoc: {},
                    removedclinicAddressProofDoc: false
                }
            }
        });
    }

    handleMainImageRemoveAddProof = (file) => {
        this.setState({ clinicAddressProofDoc: {}, removedclinicAddressProofDoc: true })
        return true;
    }

    handleMainImageideProof = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.identityProofDoc).length === 0 && prevState.removedidentityProofDoc === false){
                return{
                    identityProofDoc: file,
                    removedidentityProofDoc: false
                }
            }
            else{
                return{
                    // identityProofDoc: {},
                    removedidentityProofDoc: false
                }
            }
        });
    }

    handleMainImageRemoveideProof = (file) => {
        this.setState({ identityProofDoc: {}, removedidentityProofDoc: true })
        return true;
    }

    handleMainImageregProof = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.registrationProofDoc).length === 0 && prevState.removedregistrationProofDoc === false){
                return{
                    registrationProofDoc: file,
                    removedregistrationProofDoc: false
                }
            }
            else{
                return{
                    // registrationProofDoc: {},
                    removedregistrationProofDoc: false
                }
            }
        });
    }

    handleMainImageRemoveregProof = (file) => {
        this.setState({ registrationProofDoc: {}, removedregistrationProofDoc: true })
        return true;
    }


    enterLoading = () => {
        this.setState({
            loading: true
        })
    }

    messageClose = () => {
        this.props.history.push('/registrations')
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
    console.log(values)
    // return
  
    if (Object.keys(values.adhaarProof).length !=0)
    {
      if(Object.keys(this.state.identityProofDoc).length>0 && !values.adhaarProof.hasOwnProperty('key')){
       values.adhaarProof = {
         bucket: bucket,
         region: region,
         localUri: values.adhaarProof.fileList[0].originFileObj,
         key: `Document/${values.adhaarProof.fileList[0].name}`,
         mimeType:values.adhaarProof.fileList[0].type
       }

      }
      else{
       values.adhaarProof = {
         bucket: bucket,
         region:region,
         key:values.adhaarProof.key
       };
      }
    }

    if (Object.keys(values.clinicadd).length !=0)
    {
      if(Object.keys(this.state.clinicAddressProofDoc).length>0 && !values.clinicadd.hasOwnProperty('key')){
       values.clinicadd = {
         bucket: bucket,
         region: region,
         localUri: values.clinicadd.fileList[0].originFileObj,
         key: `Document/${values.clinicadd.fileList[0].name}`,
         mimeType:values.clinicadd.fileList[0].type
       }

      }
      else{
       values.clinicadd = {
         bucket: bucket,
         region:region,
         key:values.clinicadd.key
       };
      }
    }

    if (Object.keys(values.registrationproof).length !=0)
    {
      if(Object.keys(this.state.registrationProofDoc).length>0 && !values.registrationproof.hasOwnProperty('key')){
       values.registrationproof = {
         bucket: bucket,
         region: region,
         localUri: values.registrationproof.fileList[0].originFileObj,
         key: `Document/${values.registrationproof.fileList[0].name}`,
         mimeType:values.registrationproof.fileList[0].type
       }

      }
      else{
       values.registrationproof = {
         bucket: bucket,
         region:region,
         key:values.registrationproof.key
       };
      }
    }
console.log("val....",values.adhaarProof,values.registrationproof,values.clinicadd)
// return
          if (!err) {
            this.enterLoading()
            try {
              this.props.client.mutate({
                mutation: updateDoctor,
                variables: {
                    id:this.props.location.state.data.id,
                            mob: values.mob,
                            email:values.email,
                            fName:values.fname,
                            lName:values.lname,
                            fullName:values.fname+' '+values.lname,
                            gender:values.gender,
                            speciality:values.speciality,
      subSpeciality:values.subSpeciality,
      clinicName:values.cliName,
      addressOne:values.addressOne,
      addressTwo:values.addressTwo,
      country:values.country,
      state:values.state,
      city:values.city,
      zip:values.zip,
      registrationId:values.regNo,
      registrationCouncil:values.regCouncil,
      registrationYear:values.regYear,
      highestQualification:values.higqual,
      university:values.university,
      degreeYear:values.degreeyear,
      experienceYears:values.experienceYears,
      identityProofDoc:values.adhaarProof,
      registrationProofDoc:values.registrationproof,
      clinicAddressProofDoc:values.clinicadd,
    //   status:$status
                }
              }).then(({ data }) => {
                console.log(data)
                if (data.updateDoctor != null) {
                  message.success(<span style={{fontSize:'16px'}}>doctor updated Successfully</span>,this.messageClose);

                  this.setState({
                    loading: false
                  })
                }
              })
                .catch(err => {
                  console.log(err)
                  this.setState({
                    loading: false

                  })
                });
            }
            catch (err) {
              console.log(err)
              this.setState({
                loading: false

              })
            }
          }
          else {
            console.log(err)
    
          }
        })
      }
 
   
  
    render() {
            let data=this.props.location.state && this.props.location.state.data?this.props.location.state.data:{}
        console.log("props",data)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
    
            </div>
          );
          const uploadButton1 = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
    
            </div>
          );
          const uploadButton2 = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
    
            </div>
          );

    
        return (
            <App header={"Edit Doctor"}>
                <div>
                       
                <Form onSubmit={this.handleSubmit}>

                <Row gutter={16}>
  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="First name">
          {getFieldDecorator(`fname`, {
              initialValue:data && data.fName?data.fName:'',
              rules: [{
                  required: true,
                  message: "Please enter First name",
              },
              ]
          })(
              <Input placeholder="First name" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Last name">
          {getFieldDecorator(`lname`, {
              initialValue:data && data.lName?data.lName:'',

              rules: [{
                  required: true,
                  message: "Please enter Last name",
              },
              ]
          })(
              <Input placeholder="Last name" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Gender">
          {getFieldDecorator(`gender`, {
              initialValue:data && data.gender?data.gender:'',

              rules: [{
                  required: true,
                  message: "Please enter Gender",
              },
              ]
          })(
              <Radio.Group placeholder="Gender" style={{ width: '100%' }} >
                                                 <Radio value={'male'}>Male</Radio>
                                                <Radio value={'female'}>Female</Radio>
                                                </Radio.Group>
          )}
      </FormItem>
  </Col>

</Row>

<Row gutter={16}>
  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Mobile Number">
          {getFieldDecorator(`mob`, {
              initialValue:data && data.mob?data.mob:'',
              rules: [{
                  required: true,
                  message: "Please enter First name",
              },
              ]
          })(
              <Input placeholder="Mobile Number" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Email">
          {getFieldDecorator(`email`, {
              initialValue:data && data.email?data.email:'',

              rules: [{
                  required: true,
                  message: "Please enter Email",
              },
              ]
          })(
              <Input placeholder="Email" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Adhaar Number">
          {getFieldDecorator(`ano`, {
              initialValue:data && data.mob?data.mob:'',

              rules: [{
                  required: true,
                  message: "Please enter Adhaar Number",
              },
              ]
          })(
              <Input placeholder="Adhaar Number" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

</Row>

<Row gutter={16}>
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Aadhaar proof">
            {getFieldDecorator(`adhaarProof`, {
                initialValue:data && data.identityProofDoc?data.identityProofDoc:'',
                rules: [{
                    required: true,
                    message: "Please upload Aadhaar proof",
                },
                ]
            })
            (
                <Upload
                listType="picture-card"
                defaultFileList={Object.keys(this.state.identityProofDoc).length != 0 ? [this.state.identityProofDoc] : []}
                data={this.state.identityProofDoc}
                onChange={this.handleMainImageideProof}
                onRemove={this.handleMainImageRemoveideProof}
            >
                {Object.keys(this.state.identityProofDoc).length != 0 ? null : uploadButton}
            </Upload>
            )}
        </FormItem>
    </Col>
</Row>

<Row gutter={16}>
  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Highest qualification">
          {getFieldDecorator(`higqual`, {
                initialValue:data && data.highestQualification?data.highestQualification:'',

              rules: [{
                  required: true,
                  message: "Please enter Highest qualification",
              },
              ]
          })(
              <Input placeholder="Highest qualification" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="University">
          {getFieldDecorator(`university`, {
                initialValue:data && data.university?data.university:'',

              rules: [{
                  required: true,
                  message: "Please enter University",
              },
              ]
          })(
              <Input placeholder="University" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Degree year">
          {getFieldDecorator(`degreeyear`, {
                initialValue:data && data.degreeYear?data.degreeYear:'',

              rules: [{
                  required: true,
                  message: "Please enter Degree year",
              },
              ]
          })(
              <Input placeholder="Degree year" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

</Row>

<Row gutter={16}>
  
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Speciality">
            {getFieldDecorator(`speciality`, {
                initialValue:data && data.speciality?data.speciality:'',

                rules: [{
                    required: true,
                    message: "Please enter Speciality",
                },
                ]
            })(
                <Input placeholder="Speciality" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>

    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Sub Speciality">
            {getFieldDecorator(`subSpeciality`, {
                initialValue:data && data.subSpeciality?data.subSpeciality:'',

                rules: [{
                    required: true,
                    message: "Please enter sub Speciality",
                },
                ]
            })(
                <Input placeholder="sub Speciality" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>

    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Registration number">
            {getFieldDecorator(`regNo`, {
                initialValue:data && data.registrationId?data.registrationId:'',

                rules: [{
                    required: true,
                    message: "Please enter Registration number",
                },
                ]
            })(
                <Input placeholder="Registration number" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>
    
</Row>

<Row gutter={16}>
<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Registration council">
            {getFieldDecorator(`regCouncil`, {
                initialValue:data && data.registrationCouncil?data.registrationCouncil:'',

                rules: [{
                    required: true,
                    message: "Please enter Registration council",
                },
                ]
            })(
                <Input placeholder="Registration council" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>
  

    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Registration year">
            {getFieldDecorator(`regYear`, {
                initialValue:data && data.registrationYear?data.registrationYear:'',

                rules: [{
                    required: true,
                    message: "Please enter Registration year",
                },
                ]
            })(
                <Input placeholder="Registration year" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Yearsofexperience">
            {getFieldDecorator(`experienceYears`, {
                initialValue:data && data.experienceYears?data.experienceYears:'',

                rules: [{
                    required: true,
                    message: "Please enter Years of experience",
                },
                ]
            })(
                <Input placeholder="Years of experience" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>

    </Row>
<Row gutter={16}>

    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Registration proof">
            {getFieldDecorator(`registrationproof`, {
                initialValue:data && data.registrationProofDoc?data.registrationProofDoc:'',

                rules: [{
                    required: true,
                    message: "Please Upload Registration proof",
                },
                ]
            })(
                <Upload
                listType="picture-card"
                defaultFileList={Object.keys(this.state.registrationProofDoc).length != 0 ? [this.state.registrationProofDoc] : []}
                data={this.state.registrationProofDoc}
                onChange={this.handleMainImageregProof}
                onRemove={this.handleMainImageRemoveregProof}
            >
                {Object.keys(this.state.registrationProofDoc).length != 0 ? null : uploadButton1}
            </Upload>

            )}
        </FormItem>
    </Col>
  
</Row>

<Row gutter={16}>
  
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Clinic name">
          {getFieldDecorator(`cliName`, {
                initialValue:data && data.clinicName?data.clinicName:'',

              rules: [{
                  required: true,
                  message: "Please enter Clinic name",
              },
              ]
          })(
              <Input placeholder="Clinic name" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>
  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Pin code">
          {getFieldDecorator(`zip`, {
                initialValue:data && data.zip?data.zip:'',

              rules: [{
                  required: true,
                  message: "Please enter Pin code",
              },
              ]
          })(
              <Input placeholder="Pin code" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Address One">
          {getFieldDecorator(`addressOne`, {
                initialValue:data && data.addressOne?data.addressOne:'',

              rules: [{
                  required: true,
                  message: "Please enter address One",
              },
              ]
          })(
              <Input placeholder="Address One" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

</Row>

<Row gutter={16}>
<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
      <FormItem label="Address Two">
          {getFieldDecorator(`addressTwo`, {
                initialValue:data && data.addressTwo?data.addressTwo:'',

              rules: [{
                  required: true,
                  message: "Please enter Address Two",
              },
              ]
          })(
              <Input placeholder="Address Two" style={{ width: '100%' }} />
          )}
      </FormItem>
  </Col>

  
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="City">
            {getFieldDecorator(`city`, {
                initialValue:data && data.city?data.city:'',

                rules: [{
                    required: true,
                    message: "Please enter City",
                },
                ]
            })(
                <Input placeholder="City" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>
    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="State">
            {getFieldDecorator(`state`, {
                initialValue:data && data.state?data.state:'',

                rules: [{
                    required: true,
                    message: "Please enter State",
                },
                ]
            })(
                <Select
                optionFilterProp="children"
                placeholder="Select State"
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {state.map((c)=>{
                    return(
                      <Option key={c.value} value={c.value} >{c.label}</Option>
                    )
                }) }
              </Select>
            )}
        </FormItem>
    </Col>
  
</Row>

<Row gutter={16}>

<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Country">
            {getFieldDecorator(`country`, {
                initialValue:data && data.country?data.country:'',

                rules: [{
                    required: true,
                    message: "Please enter country",
                },
                ]
            })(
                <Input placeholder="country" style={{ width: '100%' }} />
            )}
        </FormItem>
    </Col>

    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
        <FormItem label="Clinic address proof">
            {getFieldDecorator(`clinicadd`, {
                initialValue:data && data.clinicAddressProofDoc?data.clinicAddressProofDoc:'',
                rules: [{
                    required: true,
                    message: "Please upload Clinic address proof",
                },
                ]
            })(
                <Upload
                listType="picture-card"
                defaultFileList={Object.keys(this.state.clinicAddressProofDoc).length != 0 ? [this.state.clinicAddressProofDoc] : []}
                data={this.state.clinicAddressProofDoc}
                onChange={this.handleMainImageAddProof}
                onRemove={this.handleMainImageRemoveAddProof}
            >
                {Object.keys(this.state.clinicAddressProofDoc).length != 0 ? null : uploadButton2}
            </Upload>
            )}
        </FormItem>
    </Col>
</Row>




   


                            <Row gutter={16}>
                                <Col span={24}>
                                    <Button loading={this.state.loading} type="primary" htmlType="submit" style={{ background: "#389e0d", color: "#fff", marginBottom: "5%", marginRight: "20px" }} >
                                        Submit
                                    </Button>
                                    <Link to={{ pathname: '/registrations' }}>
                                        <Button type="default" style={{ background: "#f44336", color: "#fff", marginBottom: "5%" }}   >Cancel</Button>
                                    </Link>
                                </Col>
                            </Row>
        
                        </Form>
               
                    </div>
                </App>

                )
            }
        }
        const WrappedAddBuyer = Form.create()(addBuyer);
export default withApollo(WrappedAddBuyer)