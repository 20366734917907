    export default {
graphqlEndpoint: "https://sqrrpf3jnfgnnc6hv3y5dbochq.appsync-api.ap-south-1.amazonaws.com/graphql",
  region: "ap-south-1",
  apiKey: "a3po7qqq4bfhzb5mo4dnhan4cy",
  authenticationType: "AMAZON_COGNITO_USER_POOLS",
//   bucket: "hours-stay-dev",
  accessKeyId: "AKIAZK2N6OMGIBGHLBWX",
  secretAccessKey: "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",
  userPoolId: "ap-south-1_PREhpXzbO",
  userPoolWebClientId: "1j7uuc1r18svihm6j1sdh67t4s",
}
    