import gql from 'graphql-tag';
export default gql`
mutation updateSalesUser(
    $userId: String!
    $fName: String!
    $lName: String!
    $gender: Gender
    $email: String
    $city: String
    $state: String
    $zip: String
    $active: Boolean
  ){
      updateSalesUser(input:{
      userId: $userId
      fName: $fName
      lName: $lName
      gender: $gender
      email: $email
      city: $city
      state: $state
      zip: $zip
      active: $active
    }){
      mobile
      fName
      lName
      gender
      email
      city
      state
      zip
      createdAt
      active
    }
  }
  # {
  #   "userId": "8459832342",
  #   "fName": "Sohel",
  #   "lName": "K",
  #   "gender": "male",
  #   "email": "khan.sohel005@gmail.com",
  #   "city": "pune",
  #   "state": "maharashtra",
  #   "zip": "411033",
  #   "active": true
  # }
`  