import React, { Component } from 'react'
import App from '../App'
import { Table, Button, Breadcrumb,Input, Row, Col, Popconfirm, Switch, Spin, Modal,message } from 'antd'
import { withRouter,Link } from 'react-router-dom';
import { graphql, compose, withApollo,Query } from 'react-apollo';
import listSalesUsers from '../GraphQl/query/listSalesUsers'
import deleteSalesUser from '../GraphQl/Mutation/deleteSalesUser'
 class SalesPeople extends Component {

    constructor(props){
        super(props)
        this.state={
            dataSource: [],
            loading: true,
                visible: false,
                ViewBuyerData: {},
                searchText: '',
    searchedColumn: '',
    pagination: {},
    nextToken: '',
    hasMore: true,
    onSearch: true,
    search: true,
    bdata: [],
        }
    }

    fetch = (params = {}) => {
        // console.log('params:', params);
        // this.setState({ loading: true });
        this.props.client.query({
          query: listSalesUsers,
          fetchPolicy: 'network-only',
          variables: {
            nextToken: this.state.nextToken,
            // inputParam: {}
          }
        }).then(data => {
          const dataVal = data.data.listSalesUsers.items
          const pagination = { ...this.state.pagination };
          const token = data.data.listSalesUsers.nextToken
    
          const dfg = this.state.bdata.concat(dataVal)
          this.setState({
            bdata: dfg
          })
          this.createDatasource(this.state.bdata)
    
          this.setState({
            loading: false,
            nextToken: token,
            pagination,
          });
        });
      };

      handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
        if (this.state.nextToken == null) {
          this.setState({
            hasMore: false,
            onSearch: false,
            loading: false,
            search: false
          });
          return;
        }
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      };
    
      createDatasource = (data) => {
    
        let dataSource = data.map((m, i) => {
    
          return {
            key: i,
            userId:m.userId,
            mobile:m.mobile,
            fName:m.fName,
            lName:m.lName,
            gender:m.gender,
            email:m.email,
            city:m.city,
            state:m.state,
            zip:m.zip,
            createdAt:m.createdAt,
            active:m.active,
          }
        })
    
        this.setState({
          dataSource
        })
      }
    
    componentDidMount = () => {
        this.fetch()
    //     this.props.client.query({
    //       query: listSalesUsers,
    //       fetchPolicy: 'network-only',
    //       variables: {
    //         limit:20,nextToken:''
    //       },
    //   })
    //       .then(({ data }) => {
    //         if(data && data.listSalesUsers && data.listSalesUsers.items!=null){
    //             console.log(data.listSalesUsers.items)
    //             this.setState({
    //               loading:false,
    //               dataSource:data.listSalesUsers.items
    //             })
    //           }
    //       })
    //       .catch(err => {
    //         console.log("..............")
    //           console.log(`Error ${JSON.stringify(err)}`)
    //       })
  
       
      }

      handleDelete =(record)=> {
        console.log("record",record)
//   return
        this.props.client.mutate({
          mutation: deleteSalesUser,
          variables:{
            userId: record.userId
          }
        }).then(({data})=>{
          if(data.deleteSalesUser){
            let arr = this.state.dataSource;
         let i = arr.findIndex(x => x.userId == record.userId)
            arr.splice(i,1)
            this.setState({
                dataSource:arr
            },()=>{
              this.createDatasource(arr)
            })
            message.success("Sales People Deleted Successfully")
          }
        })
      }
  
  
   
    render() {

        const columns = [
            {
                title: ' First Name',
                dataIndex: 'fName',
                key: 'fName',
            },
            {
                title: ' Last Name',
                dataIndex: 'lName',
                key: 'lName',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
    
            },
         
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: 'Location',
                dataIndex: 'city',
                key: 'city',
    
            },
            
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (data) => {
                    return (
                        <div>
                            <span>
                            <Link to={{ pathname: '/editSalesPeople',
                                state: {
                                    data:data
                                }}}>

                            <Button icon="edit"  type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                       </Link>
                        </span>
                        <span>
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data)}>
                            <Button icon="delete"  type="danger" style={{ marginLeft: "5px", ursor: "pointer", }} />
                            </Popconfirm>
                        </span>
                        </div>
                    )
                }
    
            }
        ];

        return (
            <App header={"Sales People List"}>
                <div>
                <Link to={{ pathname: '/createSalesPeople'}}>
                <Button type="primary"  style={{ background: "#389e0d", color: "#fff", marginBottom: "1%", marginLeft: "87%" }}>Add Sales People</Button>
                </Link>
                { !this.state.loading ? 
                    <Table
                        dataSource={this.state.dataSource}
                        columns={columns}
                        onChange={this.handleTableChange}
              pagination={this.state.pagination}
                    />
                                :<Spin style={{height:'max',width:'800px'}} /> }
                                </div>
            </App>
        )
    }
}
export default withApollo(SalesPeople)