import React from 'react';
import { Button, Col, Icon, Layout, Menu,  Tooltip, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { Link,withRouter } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import './assets/less/main.less'
import './assets/css/main.css'
const { Header, Sider,Footer, Content } = Layout;
const { SubMenu } = Menu;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      activemenuKey: '',
      defaultOpen: '',
      openKeys: []

    };
  }

            static showMenuSelected(url) {
              const pathArr = url.split('/').filter(pathVal => pathVal != '');
              const pathName = pathArr[0];
              let activeKey = '0';

              switch (pathName) {
                case undefined:
                activeKey = '3';
                break;

                    case 'enquiries':
                    activeKey = '3';
                    break;
                    case 'registrations':
                      activeKey = '4';
                      break;
                      case 'editdoctor':
                        activeKey = '4';
                        break;
                      case 'SalesPeople':
                        activeKey = '5';
                        break;
                        case 'editSalesPeople':
                          activeKey = '5';
                          break;
                          case 'createSalesPeople':
                            activeKey = '5';
                            break;
                            case 'SignUpList':
                            activeKey = '1';
                            break;
    
                          
                default:
                  activeKey = '3';
              }
              return {
                activeKey
              };
            }
            static getDerivedStateFromProps(nextProps, nextState) {
              const getActiveMenuId = App.showMenuSelected(nextProps.match.url);
              return {
                activemenuKey: getActiveMenuId.activeKey,
              };
          
          }

            toggle = () => {
              this.setState({
                collapsed: !this.state.collapsed,
              });
            };

          handleClick = (e) => {
            console.log('click ', e);
            console.log('key',e.keyPath[1])
        
          }
          handleSignOut = () => {
            localStorage.removeItem("role");
            localStorage.removeItem("terms"); 
               Auth.signOut()
              .then(() => {
                window.location = window.location.origin;
                // window.location.reload(true)
              })
              .catch(err => console.log(err));
          };
          onCollapse = collapsed => {
            this.setState({ collapsed });
          };
          onOpenChange = openKeys => {
            const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
              this.setState({ openKeys });
            } else {
              this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
              });
            }
          };
 
  render(){
    // console.log(this.props.children)
    const { children} = this.props.children.props;
    const { activemenuKey } = this.state;
  return (

                <Layout>
                  <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => { console.log(broken); }}
                    onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
                  >
                       <div
                    className="logo"
                    style={{
                      height: '37px',
                      margin: '16px'
                    }}
                  >
                    {/* {this.state.collapsed ? (
                      <img
                        src="https://www.recaho.com/img/xyz/logosalon.png"
                        className="header-logo"
                        style={{ width: '30px' }}
                      />
                    ) : ( */}
                      <img
                        src={require("./assets/images/recaho2.png")}
                        style={{ width: '100%', height: '40px' }}
                      />
                  </div>
                    <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]}>
                    <Menu.Item key="3">
                      <Link to="/">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Manage Enquiry</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="4">
                      <Link to="/registrations">
                        <Icon type="code-sandbox" />
                        <span className="nav-text"> Manage Doctor</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="5">
                      <Link to="/SalesPeople">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Manage Sales</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="1" onClick={this.handleSignOut}>
                        <Icon type="logout" />
                        <span className="nav-text">Logout</span>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Layout style={{ height: "100vh" }}>
                    <Header style={{ background: '#fff', padding: 0 }} >
                    <Row>
                      <Col span={20}>
                    {/* <Icon
                          className="trigger"
                          type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.toggle}
                        /> */}
                    <h2 style={{marginLeft:'20px'}}>{this.props.header}</h2>
                      </Col>
                      {/* <Col>
                      <div style={{cursor:"pointer", padding:"10px",float:"right"}} onClick={this.handleSignOut}>
                      <Tooltip placement="left"  title="Logout">
                      <Icon type="logout"   style={{ fontSize: '30px', color: '#08c'}}/>
                      </Tooltip>
                      </div>
                      </Col> */}
                    </Row>
                    </Header>
                    <Content style={{ margin: '10px 10px 0', padding: 10,
                      background: '#fff',
                      overflow: 'auto' }}>
                      <div style={{ padding: 10, background: '#fff', minHeight: 560 }}>
                      {this.props.children}
                      </div>
                    </Content>
                  </Layout>
                </Layout>
  );
}
}
export default withRouter(App);