import gql from 'graphql-tag';
export default gql`
mutation assignEnqToUser(
    $userId: String!
    $enqId: String!
  ){
    assignEnqToUser(input:{
      userId: $userId
      enqId: $enqId
    })
  }
  # {
  #   "userId": "8459832342",
  #   "enqId": "0989431577"
  # }`