import gql from 'graphql-tag';
export default gql`
query listSalesUsers( $limit: Int $nextToken: String){
    listSalesUsers(limit: $limit nextToken: $nextToken) {
      items{
      userId
      mobile
      fName
      lName
      gender
      email
      city
      state
      zip
      createdAt
      active
        
      }
      nextToken
    }
  }
  # {
  #   "limit": 20,
  #   "nextToken": ""
  # }
`  