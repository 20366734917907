import Amplify, { Auth } from 'aws-amplify';
import {
	withAuthenticator,
	ConfirmSignIn,
	ForgotPassword,
	RequireNewPassword,
	SignIn,
	VerifyContact
} from 'aws-amplify-react';
import AWSAppSyncClient from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';
import * as AWS from 'aws-sdk';
import { ApolloProvider } from 'react-apollo';
import React, { Component } from 'react';
import Routes from './routers';
import AppSyncConfig from './Configs/AppSy'

Amplify.configure(AppSyncConfig);

class ApolloIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const EndPoint = AppSyncConfig.graphqlEndpoint;
		const Region = AppSyncConfig.region;
// console.log('end point',EndPoint,Region)
		const client = new AWSAppSyncClient({
			url:EndPoint,
			region:Region,
			auth: {
				type: AppSyncConfig.authenticationType,
				apiKey: AppSyncConfig.apiKey,
				jwtToken: async () =>
                    (await Auth.currentSession()).getIdToken().getJwtToken()
			},
			disableOffline: true,
			complexObjectsCredentials: () => {
				return new AWS.Credentials({
					accessKeyId: AppSyncConfig.accessKeyId,
					secretAccessKey: AppSyncConfig.secretAccessKey
				});
			}
		});
		// console.log(client)
		return (
			<ApolloProvider client={client}>
				<Rehydrated>
				<Routes />
				</Rehydrated>
			</ApolloProvider>
		);
	}
}
export default withAuthenticator(ApolloIndex, false, [
	<SignIn />,
	<ConfirmSignIn />,
	<VerifyContact />,
	<ForgotPassword />,
	<RequireNewPassword />
]);
// export default ApolloIndex;