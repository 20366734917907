import gql from 'graphql-tag';
export default gql`
query listDoctors(
  $limit: Int
  $nextToken: String
){
  listDoctors(limit: $limit nextToken: $nextToken){
    items{
      id
    mob
    email
    fName
    lName
    fullName
    gender
    speciality
    subSpeciality
    clinicName
    addressOne
    addressTwo
    country
    createdAt
    state
    city
    zip
    status
    registrationId
    registrationCouncil
    registrationYear
    highestQualification
    university
    degreeYear
    experienceYears
    identityProofDoc{
      bucket
      key
      region
    }
    registrationProofDoc{
      bucket
      key
      region
    }
    clinicAddressProofDoc{
      bucket
      key
      region
    }
    }
    nextToken
  }
}
# {
#    "limit": 10,
#   "nextToken": ""
# }`