import gql from 'graphql-tag';
export default gql`
mutation deleteSalesUser( $userId: String! ){
    deleteSalesUser(userId: $userId ) {
      mobile
      fName
      lName
    }
  }
  # {
  #   "userId": "8459832342"
  # }
  `