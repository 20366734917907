import React, { Component } from 'react'
import App from '../App'
import { Table, Button, Breadcrumb, Input, Row, Col, Popconfirm, Switch, Spin, Modal,Select,DatePicker, message,Form } from 'antd'
import { withRouter, Link } from 'react-router-dom';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import { CSVLink, CSVDownload } from "react-csv";
import listSignups from '../GraphQl/query/listSignups'
import assignEnqToUser from '../GraphQl/Mutation/assignEnqToUser'
import listSalesUsers from '../GraphQl/query/listSalesUsers'
import moment from 'moment';
const csvData = [
  ["firstname", "lastname", "email"],
  ["sam", "shende", "sam@gmail.com"],
  ["sandip", "shende", "ss@gmail.com"]
];
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item

const headers = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Mobile", key: "mob" },
  { label: "City", key: "location" },
  { label: "Assign User", key: "assignUser" },
  { label: "Profession", key: "profession" },
];

class Enquiries extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      loading: false,
      visible: false,
      searchText: '',
      searchedColumn: '',
      selectedRowKeys: [], // Check here to configure the default column
      cdvNewData: [],
      sDate:'',
      eDate:'',
      pagination: {
      },
      nextToken: '',
      hasMore: true,
      onSearch: true,
      search: true,
      bdata: [],
      assignUserData1:{},
      status:[],
      clearFilter:true,
      applyFilter:false
    }
  }
  fetch = (params = {}) => {
    // console.log('params:', params);
    // this.setState({ loading: true });
    this.props.client.query({
      query: listSignups,
      fetchPolicy: 'network-only',
      variables: {
        limit:200,
        nextToken: this.state.nextToken,
        // inputParam: {}
      }
    }).then(data => {
      const dataVal = data.data.listSignups.items
      const pagination = { ...this.state.pagination };
      const token = data.data.listSignups.nextToken

      const dfg = this.state.bdata.concat(dataVal)
      this.setState({
        bdata: dfg
      })
      this.createDatasource(this.state.bdata.sort((a,b)=> {return a.createdAt > b.createdAt ? 1 : -1}))
      // data.getFAQs.sort((a,b)=> {return a.displayOrder > b.displayOrder ? 1 : -1})
      this.setState({
        loading: false,
        nextToken: token,
        pagination,
      });
    });
  };

  createDatasource = (data) => {

    let dataSource = data.map((m, i) => {

      return {
        key: i,
        id: m.id,
        siteId: m.siteId,
        mob: m.mob,
        name: m.name,
        mobile: m.mobile,
        email: m.email,
        profession: m.profession,
        state: m.state,
        location: m.location,
        source: m.source,
        createdAt: m.createdAt,
        assignUser:m.assignUser?m.assignUser:'--'
      }
    })

    this.setState({
      dataSource
    })
  }
  createDataSourceAssignUser=(data)=>{
    let arr=[]
    data && data.map((i,j)=>{
      let obj={
        value:i.userId,
        label:i.fName+' '+i.lName
      }
      arr.push(obj)
    })
    console.log("data",arr)
this.setState({
  status:arr
})
  }

  componentDidMount = () => {
    this.fetch();

           this.props.client.query({
          query: listSalesUsers,
          fetchPolicy: 'network-only',
          variables: {
            nextToken:''
          },
      })
          .then(({ data }) => {
            if(data && data.listSalesUsers && data.listSalesUsers.items!=null){
                console.log(data.listSalesUsers.items)
                this.setState({
                  data:data.listSalesUsers.items
                }, () => { this.createDataSourceAssignUser(data.listSalesUsers.items) })
              }
          })
          .catch(err => {
            console.log("..............")
              console.log(`Error ${JSON.stringify(err)}`)
          })
  
 

    //   this.props.client.query({
    //     query: listSignups,
    //     fetchPolicy: 'network-only',
    //     variables: {
    //       limit:10,nextToken:''
    //     },
    // })
    //     .then(({ data }) => {
    //       if(data && data.listSignups && data.listSignups.items!=null){
    //           console.log(data.listSignups.items)
    //           this.setState({
    //             loading:false,
    //             dataSource:data.listSignups.items
    //           })
    //         }
    //     })
    //     .catch(err => {
    //       console.log("..............")
    //         console.log(`Error ${JSON.stringify(err)}`)
    //     })


  }

  enterLoading(){
    this.setState({
      updateloading:true
    })
  }

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {
        this.enterLoading()
        try {
          this.props.client.mutate({
            mutation: assignEnqToUser,
            variables: {
              userId:values.status,
              enqId:this.state.assignUserData.mob
            }
          }).then(({ data }) => {
            console.log(data)
            if (data.assignEnqToUser != null) {
              message.success(<span style={{ fontSize: '16px' }}>Enquiry Successfully Assign To User</span>);
              let data=this.state.dataSource
              this.setState({
                updateloading: false,
                visible:false,
                dataSource:null,
                loading:true
              },()=>{this.assignUserNew(data,values.status)})
              
            }
          })
            .catch(err => {
              console.log(err)
              this.setState({
                updateloading: false,
                visible:false

              })
            });
        }
        catch (err) {
          console.log(err)
          this.setState({
            updateloading: false,
          })
        }
      }
      else {
        console.log(err)

      }
    })
  }

  assignUserNew=(data,val)=>{
    console.log("new data",data)
    console.log("new data ..............",val)


    let dataSource = data.map((m, i) => {
      if (m.mob == this.state.assignUserData.mob) {
        return {
          key: i,
          id: m.id,
          siteId: m.siteId,
          mob: m.mob,
          name: m.name,
          mobile: m.mobile,
          email: m.email,
          profession: m.profession,
          state: m.state,
          location: m.location,
          source: m.source,
          createdAt: m.createdAt,
          assignUser:val
        }
      }
      else {
        return {
          key: i,
          id: m.id,
          siteId: m.siteId,
          mob: m.mob,
          name: m.name,
          mobile: m.mobile,
          email: m.email,
          profession: m.profession,
          state: m.state,
          location: m.location,
          source: m.source,
          createdAt: m.createdAt,
          assignUser: m.assignUser?m.assignUser:'--'
        }
      }

    })
    console.log("new d s",dataSource)

    this.setState({
      dataSource,
      loading:false
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    if (this.state.nextToken == null) {
      this.setState({
        hasMore: false,
        onSearch: false,
        loading: false,
        search: false
      });
      return;
    }
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  onSelectChange = (selectedRowKeys, ss) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    console.log('ss: ', ss);

    this.setState({
      cdvNewData: ss,
      selectedRowKeys
    })

  }
  changeStatus = (data) => {
    console.log("data", data)
    this.setState({
      visible: true,
      assignUserData: data
    });
  }

  
  onChange=(date, dateString)=> {
    console.log( "date:", dateString);
    let sd=dateString[0]
    let ed=dateString[1]
    console.log(sd,ed)
    let fedate = Math.floor(new Date(sd) / 1000);
    let sedate = Math.floor(new Date(ed) / 1000);
    console.log("epoch",fedate,sedate)
    this.setState({
        sDate:fedate,
        eDate:sedate,
    })
  }

  filterdDataSource=(data)=>{

    let dataSource = []
    data.map((m, i) => {
if(m.createdAt>=this.state.sDate && m.createdAt<=this.state.eDate){
      let obj= {
        key: i,
        id: m.id,
        siteId: m.siteId,
        mob: m.mob,
        name: m.name,
        mobile: m.mobile,
        email: m.email,
        profession: m.profession,
        state: m.state,
        location: m.location,
        source: m.source,
        createdAt: m.createdAt,
        assignUser:m.assignUser
      }
      dataSource.push(obj)
    }
    })
    this.setState({
      dataSource,
    })

  }

  applyFilterFun=()=>{
    console.log("app")
    let data=this.state.dataSource
this.filterdDataSource(data)
    this.setState({
      applyFilter:true,
      clearFilter:false,
      dataSource1:data
    })


  }
  clearFilterFun=()=>{
    console.log("clear")
    this.setState({
      applyFilter:false,
      clearFilter:true,
      dataSource:this.state.dataSource1
    })
  }


  render() {
    console.log("ssss",this.state.dataSource)
    const { selectedRowKeys, selectedRows } = this.state
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const hasSelected = selectedRowKeys.length > 0;


    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        fixed: 'left',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Mobile',
        dataIndex: 'mob',
        key: 'mob',
        ...this.getColumnSearchProps('mob'),
      },
      {
        title: 'Enquiry Date',
        // dataIndex: 'edate',
        key: 'edate',
        // ...this.getColumnSearchProps('createdAt'),
        render: (data) => {
          return (
            <div>
              <span>{moment.unix(data.createdAt).format("YYYY/MM/DD")}</span>

            </div>
          )
        }

      },
      {
        title: 'City',
        dataIndex: 'location',
        key: 'location',
        ...this.getColumnSearchProps('location'),
      },
      {
        title: 'Profession',
        dataIndex: 'profession',
        key: 'profession',
        ...this.getColumnSearchProps('profession'),


      },
      {
        title: 'Assign User',
        dataIndex: 'assignUser',
        key: 'assignUser',
        ...this.getColumnSearchProps('assignUser'),

      //   render: (data) => {
      //     return (
      //         <div>
      //               <p>{data && data.assignUser?data.assignUser:'--'}</p>
      //         </div>
      //     )
      // }
      },

      {
          title: 'Action',
          key: 'action',
          width: 150,
          render: (data) => {
              return (
                  <div>
                      <span>
                   
                          <Button onClick={() => this.changeStatus(data)}  type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} >
                            Assign User
                            </Button>
                      </span>
                  </div>
              )
          }

      }
    ];

    return (
      <App header={"Enquiries List"}>
        <div>
          <span>
            <CSVLink filename={"doc-file.csv"} data={this.state.cdvNewData} headers={headers}>
              <Button disabled={this.state.cdvNewData.length != 0 ? false : true} type="primary" size='default' style={{ marginLeft: "90%", ursor: "pointer", marginBottom: '10px' }}><DownloadOutlined />Download</Button>
            </CSVLink>
          </span>
          <div style={{marginLeft:'53%',justifyContent:'space-between'}}>
            <p>Enquiry Date Range:</p>
          <RangePicker onChange={this.onChange}
                                        format={dateFormat} />
          {this.state.clearFilter && <Button disabled={this.state.sDate==''?true:false} style={{marginLeft:'10px'}} type="primary" size='default'  onClick={this.applyFilterFun}>Apply Filter</Button>}
      {this.state.applyFilter &&    <Button style={{marginLeft:'10px'}} type="primary" size='default' onClick={this.clearFilterFun}>Clear Filter</Button> }

          </div><br/>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          {!this.state.loading ?
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              rowSelection={rowSelection}
              onChange={!this.state.applyFilter?this.handleTableChange:this.onFun}
             pagination={!this.state.applyFilter?this.state.pagination&&{pageSize:100}:{pageSize:100}}
              scroll={{ x: 1300 }}

            // pagination={false}
            // bordered={true}
            />
            : <Spin style={{ height: 'max', width: '800px' }} />}

        <Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
          >
              <Form onSubmit={this.handleSubmit}>
              <FormItem label="User Name">
                {getFieldDecorator(`status`, {
                  rules: [{
                    required: true,
                    message: "Please enter First name",
                  },
                  ]
                })(
                  <Select
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              
                  >
                    {this.state.status.map((c) => {
                      return (
                        <Option key={c.value} value={c.value}>{c.label}</Option>
                      )
                    })}
                  </Select>
                )}
              </FormItem>
              <Button loading={this.state.updateloading} type="primary" htmlType="submit" style={{ background: "#389e0d", color: "#fff", marginBottom: "5%", marginRight: "20px" }} >
                Assign User
                </Button>

            </Form>
          </Modal>
        </div>
      </App>
    )
  }
}

const WrappedEnquiries = Form.create()(Enquiries);
export default withApollo(WrappedEnquiries)