import gql from 'graphql-tag';
export default gql`
mutation createSalesUser(
    $mobile: String!
    $fName: String!
    $lName: String!
    $gender: Gender
    $email: String
    $city: String
    $state: String
    $zip: String
  ){
      createSalesUser(input:{
      mobile: $mobile
      fName: $fName
      lName: $lName
      gender: $gender
      email: $email
      city: $city
      state: $state
      zip: $zip
    }){
      mobile
      fName
      lName
      gender
      email
      city
      state
      zip
      createdAt
      active
    }
  }
  # {
  #   "mobile": "8459832342",
  #   "fName": "Sohel",
  #   "lName": "khan",
  #   "gender": "male",
  #   "email": "khan.sohel005@gmail.com",
  #   "city": "pune",
  #   "state": "maharashtra",
  #   "zip": "411033"
  # }`  