import React, { Component } from 'react'
import App from '../App'
import { Table, Button, Breadcrumb,Input, Row, Col, Popconfirm, Switch, Spin, Modal,message } from 'antd'
import { withRouter,Link } from 'react-router-dom';
import { DownloadOutlined,SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { graphql, compose, withApollo,Query } from 'react-apollo';
import listSignups from '../GraphQl/query/listSignups'
import updateStatus from '../GraphQl/Mutation/updateStatus'
 class SignUpList extends Component {

    constructor(props){
        super(props)
        this.state={
            dataSource: [],
            loading: true,
                visible: false,
                ViewBuyerData: {},
                searchText: '',
    searchedColumn: '',
        }
    }

    componentDidMount = () => {

        this.props.client.query({
          query: listSignups,
          fetchPolicy: 'network-only',
          variables: {
            limit:10,nextToken:''
          },
      })
          .then(({ data }) => {
            if(data && data.listSignups && data.listSignups.items!=null){
                console.log(data.listSignups.items)
                this.setState({
                  loading:false,
                  dataSource:data.listSignups.items
                })
              }
          })
          .catch(err => {
            console.log("..............")
              console.log(`Error ${JSON.stringify(err)}`)
          })
}  
fun=()=>{
    try {
        this.props.client.mutate({
            mutation: updateStatus,
            variables: {
                id:"101010101010",
                status:'APPROVED',
            }
        }).then(({ data }) => {
            console.log(data)
            if (data.updateStatus != null) {
               
            }
        })
            .catch(err => {
    console.log(err)
             
            });
    }
    catch (err) {
    console.log(err)
        this.setState({
            loading: false,
        })
    }
}


  
   
    render() {

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
    
            },
         
            {
                title: 'Mobile',
                dataIndex: 'mob',
                key: 'mob',
    
            },

            {
                title: 'Profession',
                dataIndex: 'profession',
                key: 'profession',
            },
            
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (data) => {
                    return (
                        <div>
                            <span>
                            <Link to={{ pathname: '/editSalesPeople',
                                state: {
                                    data:data
                                }}}>

                            <Button icon="edit"  type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                       </Link>
                        </span>
                        <span>
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.fun()}>
                            <Button icon="delete"  type="danger" style={{ marginLeft: "5px", ursor: "pointer", }} />
                            </Popconfirm>
                        </span>
                        </div>
                    )
                }
    
            }
        ];

        return (
            <App header={"SignUp List"}>
                <div>
                         { !this.state.loading ? 
                    <Table
                        dataSource={this.state.dataSource}
                        columns={columns}
                    />
                                :<Spin style={{height:'max',width:'800px'}} /> }
                                </div>
            </App>
        )
    }
}
export default withApollo(SignUpList)



/*
import gql from 'graphql-tag';
export default gql`
mutation updateStatus(
    $id: String!
    $status: String!
    ){
        updateStatus(
          id: $id
          status: $status
        ){
            id
      state
      status
      fullName
        }
    }`

    import gql from 'graphql-tag';
export default gql`
mutation updateStatus{
    updateStatus(id:$id, status:$status){
      id
      state
      status
      fullName
    }
  }`
  

*/