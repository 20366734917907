import React, { Component } from 'react'
import App from '../App'
import { Table, Button, Form, Select, Breadcrumb, Input, Row, Col, Popconfirm, Switch, Spin, Modal, message,DatePicker } from 'antd'
import { withRouter, Link } from 'react-router-dom';
import { DownloadOutlined, SearchOutlined,LinkOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import PapaParse from 'papaparse';
import { graphql, compose, withApollo, Query } from 'react-apollo';
import listDoctors from '../GraphQl/query/listDoctors'
import updateStatus from '../GraphQl/Mutation/updateStatus'
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';
const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
const FormItem = Form.Item
const Option = Select.Option;

const headers = [
  { label: "Full Name", key: "fullName" },
  { label: "Email", key: "email" },
  { label: "City", key: "city" },
  { label: "Mobile", key: "mob" },
  { label: "Status", key: "status" },
  { label: "Gender", key: "gender" },
  { label: "Highest Qualification", key: "highestQualification" },
  { label: "Registration Council", key: "registrationCouncil" },
  { label: "Registration Year", key: "registrationYear" },
  { label: "Speciality", key: "speciality" },
  { label: "Sub Speciality", key: "subSpeciality" },
  { label: "University", key: "university" },
  { label: "Address One", key: "addressOne" },
  { label: "Address Two", key: "addressTwo" },
  { label: "Country", key: "country" },
  { label: "State", key: "state" },
  { label: "Pin Code", key: "zip" },
  { label: "degreeYear", key: "degreeYear" },
  { label: "Experience Years", key: "experienceYears" },

];

const status = [
  { value: 'NEW', label: 'NEW' },
  { value: 'IN_REVIEW', label: 'IN_REVIEW' },
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'REJECTED', label: 'REJECTED' },
]

class Registrations extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dataSource: [],
      loading: true,
      visible: false,
      searchText: '',
      searchedColumn: '',
      visible: false,
      updateloading: false,
      selectedRowKeys: [], // Check here to configure the default column
      cdvNewData: [],
      pagination:{},
      nextToken:'',
      hasMore: true,
      onSearch: true,
      search:true,
      bdata:[],
      sDate:'',
      eDate:'',
      clearFilter:true,
      applyFilter:false
    }
  }
  fetch = (params = {}) => {
    // console.log('params:', params);
    // this.setState({ loading: true });
    this.props.client.query({
      query: listDoctors,
      fetchPolicy: 'network-only',
      variables: {
        limit:200,
        nextToken: this.state.nextToken,
      }
    }).then(data => {
      const dataVal = data.data.listDoctors.items
      const pagination = { ...this.state.pagination };
      const token = data.data.listDoctors.nextToken

      const dfg = this.state.bdata.concat(dataVal)
      this.setState({
        bdata: dfg
      })
      this.createDatasource(this.state.bdata.sort((a,b)=> {return a.createdAt > b.createdAt ? 1 : -1}))

      this.setState({
        loading: false,
        nextToken: token,
        pagination,
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    if (this.state.nextToken == null) {
      this.setState({
        hasMore: false,
        onSearch: false,
        loading: false,
        search: false
      });
      return;
    }
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  createDatasource = (data) => {

    let dataSource = data.map((m, i) => {
      let a=m.createdAt.split("T")
      let fedate = Math.floor(new Date(a[0]) / 1000);
      // console.log("ns.......",fedate)
      return {
        key: i,
        id:m.id,
    mob:m.mob,
    email:m.email,
    fName:m.fName,
    lName:m.lName,
    fullName:m.fullName,
    gender:m.gender,
    speciality:m.speciality,
    subSpeciality:m.subSpeciality,
    clinicName:m.clinicName,
    addressOne:m.addressOne,
    addressTwo:m.addressTwo,
    country:m.country,
    state:m.state,
    city:m.city!=null?m.city:'--',
    createdAt:fedate,
    zip:m.zip,
    status:m.status,
    registrationId:m.registrationId,
    registrationCouncil:m.registrationCouncil,
    registrationYear:m.registrationYear,
    highestQualification:m.highestQualification,
    university:m.university,
    degreeYear:m.degreeYear,
    experienceYears:m.experienceYears,
    registrationProofDoc:m.registrationProofDoc,
    clinicAddressProofDoc:m.clinicAddressProofDoc,
    identityProofDoc:m.identityProofDoc
      }
    })
    console.log('mmm',dataSource)
// return
    this.setState({
      dataSource
    })
  }

componentDidMount = () => {
    this.fetch()

   
  }

  changeStatus = (data) => {
    console.log("data", data)
    this.setState({
      visible: true,
      id: data.id
    });
  }

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  enterLoading() {
    this.setState({
      updateloading: true
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {
        this.enterLoading()
        try {
          this.props.client.mutate({
            mutation: updateStatus,
            variables: {
              id: this.state.id,
              status: values.status,
            }
          }).then(({ data }) => {
            console.log(data)
            if (data.updateStatus != null) {
              message.success(<span style={{ fontSize: '16px' }}>Status updated Successfully</span>);
              let data=this.state.dataSource
              this.setState({
                updateloading: false,
                visible:false,
                loading:false
                // loading: true,
                // dataSource:null
              },()=>{this.updateStatusNew(values.status,data)})
            }
          })
            .catch(err => {
              console.log(err)
              this.setState({
                updateloading: false,
              })
            });
        }
        catch (err) {
          console.log(err)
          this.setState({
            updateloading: false,
          })
        }
      }
      else {
        console.log(err)

      }
    })
  }


  updateStatusNew=(status,data)=>{
    let dataSource = data.map((m, i) => {
if(m.id==this.state.id){
      return {
        key: i,
        id:m.id,
    mob:m.mob,
    email:m.email,
    fName:m.fName,
    lName:m.lName,
    fullName:m.fullName,
    gender:m.gender,
    speciality:m.speciality,
    subSpeciality:m.subSpeciality,
    clinicName:m.clinicName,
    addressOne:m.addressOne,
    addressTwo:m.addressTwo,
    country:m.country,
    state:m.state,
    city:m.city!=null?m.city:'--',
    createdAt:m.createdAt,
    zip:m.zip,
    status:status,
    registrationId:m.registrationId,
    registrationCouncil:m.registrationCouncil,
    registrationYear:m.registrationYear,
    highestQualification:m.highestQualification,
    university:m.university,
    degreeYear:m.degreeYear,
    experienceYears:m.experienceYears,
    registrationProofDoc:m.registrationProofDoc,
    clinicAddressProofDoc:m.clinicAddressProofDoc,
    identityProofDoc:m.identityProofDoc
      }
    }
    else{
      return {
        key: i,
        id:m.id,
    mob:m.mob,
    email:m.email,
    fName:m.fName,
    lName:m.lName,
    fullName:m.fullName,
    gender:m.gender,
    speciality:m.speciality,
    subSpeciality:m.subSpeciality,
    clinicName:m.clinicName,
    addressOne:m.addressOne,
    addressTwo:m.addressTwo,
    country:m.country,
    state:m.state,
    city:m.city!=null?m.city:'--',
    createdAt:m.createdAt,
    zip:m.zip,
    status:m.status,
    registrationId:m.registrationId,
    registrationCouncil:m.registrationCouncil,
    registrationYear:m.registrationYear,
    highestQualification:m.highestQualification,
    university:m.university,
    degreeYear:m.degreeYear,
    experienceYears:m.experienceYears,
    registrationProofDoc:m.registrationProofDoc,
    clinicAddressProofDoc:m.clinicAddressProofDoc,
    identityProofDoc:m.identityProofDoc
      }
    }


    })

    this.setState({
      dataSource
    })


  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onSelectChange = (selectedRowKeys, ss) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    console.log('ss: ', ss);

    this.setState({
      cdvNewData: ss,
      selectedRowKeys
    })

  }

  onChange=(date, dateString)=> {
    console.log( "date:", dateString);
    let sd=dateString[0]
    let ed=dateString[1]
    console.log(sd,ed)
    let fedate = Math.floor(new Date(sd) / 1000);
    let sedate = Math.floor(new Date(ed) / 1000);
    console.log("epoch",fedate,sedate)
    this.setState({
        sDate:fedate,
        eDate:sedate,
    })
  }

  filterdDataSource=(data)=>{

    let dataSource = []
    data.map((m, i) => {
if(m.createdAt>=this.state.sDate && m.createdAt<=this.state.eDate){
      let obj= {
        key: i,
        id:m.id,
    mob:m.mob,
    email:m.email,
    fName:m.fName,
    lName:m.lName,
    fullName:m.fullName,
    gender:m.gender,
    speciality:m.speciality,
    subSpeciality:m.subSpeciality,
    clinicName:m.clinicName,
    addressOne:m.addressOne,
    addressTwo:m.addressTwo,
    country:m.country,
    state:m.state,
    city:m.city!=null?m.city:'--',
    createdAt:m.createdAt,
    zip:m.zip,
    status:m.status,
    registrationId:m.registrationId,
    registrationCouncil:m.registrationCouncil,
    registrationYear:m.registrationYear,
    highestQualification:m.highestQualification,
    university:m.university,
    degreeYear:m.degreeYear,
    experienceYears:m.experienceYears,
    registrationProofDoc:m.registrationProofDoc,
    clinicAddressProofDoc:m.clinicAddressProofDoc,
    identityProofDoc:m.identityProofDoc
      }
      dataSource.push(obj)
    }
    })
    this.setState({
      dataSource,
    })

  }

  applyFilterFun=()=>{
    console.log("app")
    let data=this.state.dataSource
this.filterdDataSource(data)
    this.setState({
      applyFilter:true,
      clearFilter:false,
      dataSource1:data
    })


  }
  clearFilterFun=()=>{
    console.log("clear")
    this.setState({
      applyFilter:false,
      clearFilter:true,
      dataSource:this.state.dataSource1
    })
  }


  render() {
    console.log("cdvNewData", this.state.cdvNewData)
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { selectedRowKeys, selectedRows } = this.state

    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: 'Name',
        dataIndex: 'fullName',
        key: 'fullName',
        width: 200,
        fixed: 'left',
        ...this.getColumnSearchProps('fullName'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',

      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        ...this.getColumnSearchProps('city'),
      },
      {
        title: 'Mobile',
        dataIndex: 'mob',
        key: 'mob',

      },
      {
        title: 'Status',
        key: 'status',
        render: (data) => {
          return (
            <Popconfirm title="Sure to Change Status?" onConfirm={() => this.changeStatus(data)}>
              <span style={{ cursor: 'pointer', color: data.status == 'REJECTED' ? 'red' : data.status == 'APPROVED' ? '#158a22' : '#05b18e' }} >{data.status}</span>
            </Popconfirm>
          )
        }
      },
      {
        title: 'Created Date',
        key: 'edate',
        render: (data) => {
          return (
            <div>
              <span>{moment.unix(data.createdAt).format("YYYY/MM/DD")}</span>

            </div>
          )
        }

      },
      {
        title: 'Gender',
        // dataIndex: 'gender',
        key: 'gender',
        render: (data) => {
          return (
        <div>
          {data.gender!=null?data.gender:'--'}
        </div>
          )
        }
      },
      {
        title: 'Clinic Name',
        // dataIndex: 'clinicName',
        key: 'clinicName',
        render: (data) => {
          return (
        <div>
          {data.clinicName!=null?data.clinicName:'--'}
        </div>
          )
        }
      },
      {
        title: 'Highest Qualification',
        // dataIndex: 'highestQualification',
        key: 'highestQualification',
        render: (data) => {
          return (
        <div>
          {data.highestQualification!=null?data.highestQualification:'--'}
        </div>
          )
        }
      },
      {
        title: 'Registration Council',
        // dataIndex: 'registrationCouncil',
        key: 'registrationCouncil',
        render: (data) => {
          return (
        <div>
          {data.registrationCouncil!=null?data.registrationCouncil:'--'}
        </div>
          )
        }
      },
      {
        title: 'Registration Year',
        // dataIndex: 'registrationYear',
        key: 'registrationYear',
        render: (data) => {
          return (
        <div>
          {data.registrationYear!=null?data.registrationYear:'--'}
        </div>
          )
        }
      },
      {
        title: 'Speciality',
        // dataIndex: 'speciality',
        key: 'speciality',
        render: (data) => {
          return (
        <div>
          {data.speciality!=null?data.speciality:'--'}
        </div>
          )
        }
      },
      {
        title: 'Sub Speciality',
        // dataIndex: 'subSpeciality',
        key: 'subSpeciality',
        render: (data) => {
          return (
        <div>
          {data.subSpeciality!=null?data.subSpeciality:'--'}
        </div>
          )
        }
      },
      {
        title: 'University',
        // dataIndex: 'university',
        key: 'university',
        render: (data) => {
          return (
        <div>
          {data.university!=null?data.university:'--'}
        </div>
          )
        }
      },
      {
        title: 'Address One',
        // dataIndex: 'addressOne',
        key: 'addressOne',
        render: (data) => {
          return (
        <div>
          {data.addressOne!=null?data.addressOne:'--'}
        </div>
          )
        }
      },
      {
        title: 'Address Two',
        // dataIndex: 'addressTwo',
        key: 'addressTwo',
        render: (data) => {
          return (
        <div>
          {data.addressTwo!=null?data.addressTwo:'--'}
        </div>
          )
        }
      },
      {
        title: 'Country',
        // dataIndex: 'country',
        key: 'country',
        render: (data) => {
          return (
        <div>
          {data.country!=null?data.country:'--'}
        </div>
          )
        }
      },
      {
        title: 'State',
        // dataIndex: 'state',
        key: 'state',
        render: (data) => {
          return (
        <div>
          {data.state!=null?data.state:'--'}
        </div>
          )
        }
      },
      {
        title: 'Pin Code',
        // dataIndex: 'zip',
        key: 'zip',
        render: (data) => {
          return (
        <div>
          {data.zip!=null?data.zip:'--'}
        </div>
          )
        }
      },
      {
        title: 'Degree Year',
        // dataIndex: 'degreeYear',
        key: 'degreeYear',
        render: (data) => {
          return (
        <div>
          {data.degreeYear!=null?data.degreeYear:'--'}
        </div>
          )
        }
      },
      {
        title: 'Experience Years',
        // dataIndex: 'experienceYears',
        key: 'experienceYears',
        render: (data) => {
          return (
        <div>
          {data.experienceYears!=null?data.experienceYears:'--'}
        </div>
          )
        }
      },
      {
        title: 'Identity Proof',
        key: 'identityProofDoc',
        render: (data) => {
          return (
        <div style={{marginLeft:'10px'}}>
           {data && data.identityProofDoc && data.identityProofDoc.bucket?
        //  <Button  type="primary" style={{ marginLeft: "5px", ursor: "pointer" }} >
                      <a target="_blank" href={`https://${data.identityProofDoc.bucket}.s3.${data.identityProofDoc.region}.amazonaws.com/${data.identityProofDoc.key}`}><LinkOutlined/></a>
                      // </Button>
                      :
                      <span><a onClick={()=>{message.warning("Document Not Available")}}><LinkOutlined/></a></span>
                    }

        </div>
          )
        }
      },
      {
        title: 'Registration Proof',
        key: 'registrationProofDoc',
        render: (data) => {
          return (
            <div>
           {data && data.registrationProofDoc && data.registrationProofDoc.bucket ?
                    // <Button  type="primary" style={{ marginLeft: "5px", ursor: "pointer" }} >
                      <a target="_blank" href={`https://${data.registrationProofDoc.bucket}.s3.${data.registrationProofDoc.region}.amazonaws.com/${data.registrationProofDoc.key}`}><LinkOutlined/></a>
              //  </Button>
               :
               <span><a onClick={()=>{message.warning("Document Not Available")}}><LinkOutlined/></a></span>
             }

          </div>
          )
        }
      },

      {
        title: 'Clinic Address Proof',
        key: 'clinicAddressProofDoc',
        render: (data) => {
          return (
            <div>
           {data && data.clinicAddressProofDoc && data.clinicAddressProofDoc.bucket ?
                    // <Button  type="primary" style={{ marginLeft: "5px", ursor: "pointer" }} >
                      <a target="_blank" href={`https://${data.clinicAddressProofDoc.bucket}.s3.${data.clinicAddressProofDoc.region}.amazonaws.com/${data.clinicAddressProofDoc.key}`}><LinkOutlined/></a>
              //  </Button>
               :
               <span><a onClick={()=>{message.warning("Document Not Available")}}><LinkOutlined/></a></span>
             }

          </div>
          )
        }
      },
      
      
    
      {
        title: 'Action',
        key: 'action',
        width: 170,
        render: (data) => {
          return (
            <div>
              <span>
                <Link to={{
                  pathname: '/editdoctor',
                  state: {
                    data: data
                  }
                }}>

                  <Button icon="edit" type="primary" style={{ marginLeft: "5px", ursor: "pointer", }} />
                </Link>
              </span>

            </div>
          )
        }

      }
    ];

    return (
      <App header={"Doctor List"}>
        <div>
          <span>
            <CSVLink filename={"doc-file.csv"} data={this.state.cdvNewData} headers={headers}>
              <Button disabled={this.state.cdvNewData.length != 0 ? false : true} type="primary" size='default' style={{ marginLeft: "90%", ursor: "pointer",marginBottom:'10px' }}><DownloadOutlined />Download</Button>
            </CSVLink>
          </span>
          <div style={{marginLeft:'53%',justifyContent:'space-between'}}>
          <p>Date Range:</p>
          <RangePicker onChange={this.onChange}
                                        format={dateFormat} />
          {this.state.clearFilter && <Button disabled={this.state.sDate==''?true:false} style={{marginLeft:'10px'}} type="primary" size='default'  onClick={this.applyFilterFun}>Apply Filter</Button>}
      {this.state.applyFilter &&    <Button style={{marginLeft:'10px'}} type="primary" size='default' onClick={this.clearFilterFun}>Clear Filter</Button> }

          </div><br/>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          {!this.state.loading ?
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              rowSelection={rowSelection}
              onChange={!this.state.applyFilter?this.handleTableChange:this.onFun}
              pagination={!this.state.applyFilter?this.state.pagination&&{pageSize:100}:{pageSize:100}}
              scroll={{ x: 4500 }}
            // pagination={false}
            // bordered={true}
            />
            : <Spin style={{ height: 'max', width: '800px' }} />}

          <Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
          >
            <Form onSubmit={this.handleSubmit}>
              <FormItem label="Status">
                {getFieldDecorator(`status`, {
                  // initialValue: 'NEW',
                  rules: [{
                    required: true,
                    message: "Please enter First name",
                  },
                  ]
                })(
                  <Select
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              
                  >
                    {status.map((c) => {
                      return (
                        <Option key={c.value} value={c.value}>{c.label}</Option>
                      )
                    })}
                  </Select>
                )}
              </FormItem>
              <Button loading={this.state.updateloading} type="primary" htmlType="submit" style={{ background: "#389e0d", color: "#fff", marginBottom: "5%", marginRight: "20px" }} >
                Status Update
                                    </Button>

            </Form>
          </Modal>
        </div>
      </App>
    )
  }
}
// export default withApollo(Registrations)

const WrappedRegistrations = Form.create()(Registrations);
export default withApollo(WrappedRegistrations)